import React from 'react'
import { Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import MyPage from '../components/my_page'

const PraktineInformacijaPage = () => {
  const pageTitle = 'Praktinė informacija'
  const pageCards = [
    {
      title: 'MAITINIMAS',
      body: (
        <span>
          <p>
            Vaikų maitinimo valgiaraščiai yra ne tik sudaromi pagal dietologų
            rekomendacijas bei suderinami su Vilniaus visuomenės sveikatos
            centru, tačiau ir prisitaikantys prie gamtos ritmo, sezoniškumo,
            vaikų mitybos poreikių ir pomėgių.{' '}
          </p>
          <p>
            Mažiesiems, alergiškai reaguojantiems į tam tikrus maisto produktus,
            sudarome galimybę valgiaraštį koreguoti pagal tėvelių pateiktas
            gydytojo rekomendacijas.
          </p>
          <p>
            Norime maitintis visavertiškai, tad dalyvaujame ES vykdomose vaisių
            ir pieno produktų vartojimo skatinimo programose.
          </p>
        </span>
      )
    },
    {
      title: 'DIENOS RITMAS',
      body: (
        <span>
          <p>7.30 – 9.00 val. atvykstame į darželį, apsiprantame grupėje</p>
          <p>9.00 – 9.30 val. pusryčiaujame</p>
          <p>9.30 – 11.00 val. dalyvaujame užsiėmimuose (ugdomoji veikla)</p>
          <p>11.00 – 12.00 val. einame į lauką</p>
          <p>12.00 – 12.30 val. pietaujame</p>
          <p>12.30 – 13.00 val. ruošiamės miegeliui, klausomės pasakos </p>
          <p>13.00 – 15.00 val. ramybės, poilsio ir miego metas</p>
          <p>15.00 – 15.30 val. bundame ir keliamės</p>
          <p>15.30 – 16.00 val. vakarieniaujame</p>
          <p>16.30 – 17.00 val. susibūrę į ratą aptariame dieną </p>
          <p>17.00 – 18.00 val. žaidžiame, pasitinkame tėvelius</p>
        </span>
      )
    },
    {
      title: 'LANKYMO TAISYKLĖS',
      body: (
        <span>
          <ol>
            <li>
              Jei vaikas susirgo ar dėl kitų priežasčių neatvyks į darželį,
              praneškite mums telefonu.
            </li>
            <li>
              Jei vaikas serga užkrečiama liga, karščiuoja, tą dieną likite
              namuose. Į darželį galima grįžti tik pilnai pasveikus.
            </li>
            <li>
              Vaiką paimti iš darželio gali tik tėvai, jei personalui nebuvo
              pranešta kitaip. Pasiimant vaiką kviečiame užeiti į vidų ir apie
              tai informuoti auklėtoją – nekvieskite vaiko per teritorijos tvorą
              ar iš automobilio. Taip visiems saugiau!
            </li>
            <li>
              Vaikas darželyje NEGALI TURĖTI: saldumynų ir kitokio sveikatai
              nepalankaus maisto, pinigų, vaikui ir aplinkiniams pavojų
              keliančių daiktų (kosmetinių priemonių, aštrių daiktų ir pan.),
              mobilaus telefono (bet kada galite susisiekti su darželio
              personalu). SVARBU: Per vaiko gimtadienį NELEISTINA į darželį
              atnešti jokių maisto produktų ar gėrimų. Gimtadienio nuotaiką
              kuriame šventiniais akcentais - balionais, kepurėlėmis,
              girliandomis, muilo burbulais ir pan. Šią atributiką galima
              atsinešti.
            </li>
            <li>
              Vaikas darželyje pagal poreikį GALI TURĖTI: čiulptuką, seilinuką,
              sauskelinių, drėgnų servetėlių, taip pat specializuoto maisto
              užkandžiams, jei to reikalauja vaiko sveikatos būklė.
            </li>
            <li>
              Vaikas darželyje PRIVALO TURĖTI: gydytojo pažymą apie vaiko
              sveikatą, šukutes plaukams, miego rūbelius, vidaus avalynę
              neslidžiu padu, atsarginių rūbelių komplektą, tinkamą lauko
              aprangą pagal metų laiką (kepuraitė vasarą, neperšlampančios
              pirštinės žiemą ir pan.). Kad ir ką vaikas beveiktų, apranga
              neturėtų varžyti judesių.
            </li>
            <li>
              Tėveliai kas mėnesį turi laiku atsiskaityti už darželio lankymą
              (terminą nurodys darželio administracija). 
            </li>
            <li>
              Tėveliams rekomenduojama nuolat domėtis, kaip darželyje sekasi
              vaikui. Personalas mielai pasidalins!
            </li>
          </ol>
        </span>
      )
    },
    {
      title: 'PRIĖMIMO TVARKA',
      body: (
        <span>
          <ol>
            <li>
              Ateikite, apžiūrėkite, pabūkite, domėkitės. Suderinkime susitikimo
              laiką <Link to="/kontaktai">Kontaktų skyrelyje</Link> nurodytais
              telefonais arba el. paštu.
            </li>
            <li>
              Jei pajutote mūsų laisvės dvasią ir norite ja gyventi,
              registruokitės į darželį{' '}
              <Link to="/kontaktai">Kontaktų skyrelyje</Link> nurodytais
              telefonais, el. paštu.
            </li>
            <li>
              Atvykę į darželį pasirašykite Vaiko ikimokyklinio ugdymo sutartį
              su VšĮ Mūsų pėdutės.
            </li>
            <li>
              Laikotarpyje tarp sutarties pasirašymo ir pirmosios dienos
              darželyje rekomenduosime susitikti su specialistu, kuris bus
              atsakingas už vaiką. Specialistas prisistatys, paminės, ką savo
              krepšelyje vaikas turi atsinešti į darželį, bei suderins su Jumis
              vaiko adaptacijos darželyje planą.
            </li>
            <li>
              Prieš pradedant lankyti darželį praneškite administracijai, jei
              vaikas turi specifinių su sveikata susijusių arba mitybos poreikių
              (alergija maistui ir kitkam, buvusios operacijos, traumos,
              epilepsija ir kt.).
            </li>
            <li>
              Atvykite pirmajai numatytai lankymo dienai. Kokią nuotaiką pirmąją
              dieną beatsineštumėte į darželį – ar liūdesį, apvalią riedančią
              ašarą, ar šiltą snaudulį, ar didelį, šviečiantį džiaugsmą – Jus
              pasitiksime su nemažesniu laukimu. Visi kartu stipriai norėsime,
              kad Jums viskas būtų gerai!
            </li>
            <li>Vaikai priimami į darželį nuo 1 m. </li>
          </ol>
        </span>
      )
    },
    {
      title: 'ADAPTACIJA',
      body: (
        <span>
          <p>
            Prisitaikymo lankyti darželį procesas prasideda vaikui dar būnant
            namie. Jei drauge su vaiku padirbėsite iki pirmosios lankymo dienos,
            adaptacija darželyje bus sklandesnė.
          </p>

          <h4>PRIEŠ PRADEDANT LANKYTI DARŽELĮ:</h4>
          <ul>
            <li>
              APMĄSTYKITE, kaip Jūsų vaikas jaučiasi naujoje aplinkoje, dėl ko
              dažniausiai nerimauja, ar greitai apsipranta, kas padeda greičiau
              prisitaikyti. Tikėtina, kad vaikas panašiai reaguos ir į darželio
              aplinką.
            </li>
            <li>
              SUSITIKITE su už vaiką atsakingu specialistu dar prieš pradedant
              lankyti darželį. Surinkite visą rūpimą informaciją apie darželio
              gyvenimą.
            </li>
            <li>
              PASIKALBĖKITE su vaiku apie būsimus pokyčius. Papasakokite, su
              kokiais žmonėmis vaikas susitiks (auklėtojos, kiti vaikai), kokios
              bus veiklos, aplinka ir dienotvarkė. Užtikrinkite vaikui
              suprantama kalba, kad darželyje jį paliksite tik kuriam laikui, o
              paskui pasiimsite ir kartu vyksite namo. Žinojimas, kas vyks
              ateityje, vaikui padeda ramiau priimti pokyčius, teikia jausmą,
              kad pasaulis yra kontroliuojamas ir nuspėjamas. Apie darželį
              kalbėkite kuo ramiau, taip pat, kaip pristatote jam įprastines jo
              išvykas iš namų. Vaikas neturėtų pajusti Jūsų jaudulio, nors ir
              natūralu, kad taip pat nerimaujate.
            </li>
            <li>
              PRATINKITE VAIKĄ PASILIKTI prižiūrimą kitų suaugusiųjų (auklės,
              giminaičio, šeimos draugo). Stebėkite, kaip vyksta judviejų
              išsiskyrimas pasiliekant su skirtingais žmonėmis, kas padeda jį
              palengvinti ir per kiek laiko vaikas pripranta pasilikti su kitu
              žmogumi.
            </li>
          </ul>

          <h4>PIRMOSIOMIS DIENOMIS DARŽELYJE:</h4>
          <ul>
            <li>
              Pirmą dieną atvykę į darželį, SUPAŽINDINKITE vaiką su auklėtoja.
              Rodykite vaikui, kad su auklėtoja bendrauti Jums malonu. Vaikas
              taip pat ims ja pasitikėti.
            </li>
            <li>
              Prieš išeidami PRIMINKITE vaikui jam suprantama kalba, kada
              grįšite jo pasiimti ir keliausite namo, pavyzdžiui, po pietų
              miego, po vakarienės ir pan. Stenkitės nevėluoti, nes tai
              užtikrins vaiko pasitikėjimą Jumis.
            </li>
            <li>
              ATSISVEIKINKITE trumpai ir ramiai, taip pat, kaip eilinio išėjimo
              iš namų metu. Jei vaikas verkia, atsispirkite norui jį raminti.
              Tokia Jūsų reakcija paskatins vaiką ašaromis į išsiskyrimą
              reaguoti ir kitą kartą, nes vieną kartą už ašaras jau sulaukė
              prielankumo. Vietoje to galite atsisveikinimą paversti judviejų
              smagiu ritualu, pavyzdžiui, duoti po bučinuką į kiekvieną skruostą
              ir bakstelėti nosimis. Atsisveikinimo metu vaikui daugiau dėmesio
              rodykite tada, kai jis yra ramus ir gerai nusiteikęs. Iš darželio
              niekada neišeikite slapčia, nes tai griauna vaiko pasitikėjimą
              Jumis, sukelia ypatingą nerimą, jausmą – „esu apgautas, paliktas
              vienas nežinomoje aplinkoje“.
            </li>
            <li>
              NENUVERTINKITE VAIKO NERIMO, neneikite, nesijuokite. Visos vaiko
              reakcijos pasiliekant darželyje pirmuosius kartus yra normalios.
              Tai yra vaiko jausmai, į kuriuos derėtų atliepti, įsigilinti.
              Išsiaiškinkite, kas tiksliai vaikui kelia nerimą, pavyzdžiui,
              galbūt vaikas mano, kad nebegrįšite. Tokiu atveju paaiškinkite
              vaikui, kad tikrai ateisite pasiimti, priminkite, kada tą
              padarysite. Informuokite auklėtoją, kada atvyksite, kad ji galėtų
              vaikui tai priminti, jei jis klaustų.
            </li>
            <li>
              PRISIMINKITE – vaiko nenoras išleisti tėvus rodo, kad judviejų
              ryšys stiprus ir saugus. Vaikas tiki, kad būtent Jūs galite jį
              apsaugoti nuo pavojų, o vaikas šį tikėjimą gali Jums parodyti. Jei
              tęsėsite pažadą vaikui ir nevėluosite jo pasiimti, jis greitai
              supras, kad visada sugrįžtate ir Jumis galima pasitikėti.
            </li>
            <li>
              MŪSŲ PĖDUTĖSE sudarysime sąlygas sklandžiai vaiko adaptacijai.
              Pirmosiomis dienomis darželyje kuriam laikui galėsite likti drauge
              su vaiku, jei jausite, kad to reikia. Adaptacijos laikotarpio
              trukmę ir pobūdį priderinsime prie Jūsų ir mažylio poreikių.
            </li>
          </ul>
        </span>
      )
    },
    {
      title: 'APIE MUS KALBA',
      body: (
        <span>
          <p>
            <em>
              <a href="http://vilkesnamai.com/2016/03/01/mama-pradeda-lankyti-darzeli/">
                2016-03-01 "Vilkės namai" internetinis dienoraštis - MAMA
                PRADEDA LANKYTI DARŽELĮ{' '}
              </a>
              :
            </em>{' '}
            Vieno Mūsų Pėdučių mažylio Mama dalijasi savo mintimis apie vieną
            svarbiausių įvykių jaunos šeimos gyvenime. Tai mintys apie nuoseklų
            pasiruošimą, nekantrų laukimą ir prasmingą išbuvimą su kylančiais
            jausmais, kai mažylis pradeda lankyti darželį. Galbūt kažkam tai bus
            atrama ir pagalba, ruošiantis žengti šį svarbų, bet be galo jautrų
            žingsnį.
          </p>
          <p>
            <em>
              <a href="http://vilkesnamai.com/2016/03/16/dekojimo-stebuklas/">
                2016-03-16 "Vilkės namai" internetinis dienoraštis - DĖKOJIMO
                STEBUKLAS{' '}
              </a>
            </em>{' '}
          </p>
          <p>
            <em>
              <a href="http://lietuve.lt/mokslas-ir-svietimas/naujame-darzelyje-musu-pedutes-vaikai-ugdomi-pasitelkiant-etnokulturines-tradicijas-ir-gamta">
                2016-03-21 Lietuvė.lt tinklaraštis{' '}
              </a>
            </em>{' '}
          </p>
          <p>
            <em>
              <a href="http://www.mama.lt/show-article/7810/duris-atvere-naujas-darzelis-musu-pedutes-gamtos-prieglobstyje">
                2016-03-25 Mama.lt tinklaraštis{' '}
              </a>
            </em>{' '}
          </p>
          <p>
            <em>
              <a href="https://vaikodiena.lt/musu-pedutes-jaukus-darzelis-gamtos-apsuptyje/">
                2017-10-10 „MŪSŲ PĖDUTĖS“ – jaukus darželis gamtos apsuptyje{' '}
              </a>
            </em>{' '}
          </p>
          <p>
            <em>
              <a href="https://vaikodiena.lt/privatus-ar-valstybinis-rupestis-teveliams-kuriu-vaikai-nuo-rugsejo-prades-lankyti-darzeli/">
                2018-02-12 Privatus ar valstybinis? Rūpestis tėveliams, kurių
                vaikai nuo rugsėjo pradės lankyti darželį{' '}
              </a>
            </em>{' '}
          </p>
          <p>
            <em>
              <a href="https://mamoszurnalas.lt/kur-tipena-musu-pedutes/">
                2018-11-01 "Mamos žurnalas" - "Kur tipena mūsų pėdutės"{' '}
              </a>
            </em>{' '}
          </p>
          <p>
            <em>
              <a href="https://mamoszurnalas.lt/globalus-pasaulis-ir-uzimti-tevai-kaip-siandiena-ugdyti-mazuosius-lietuvos-patriotus/?fbclid=IwAR0pv4KjyIZyjgkFv1XrcgDP9i1z6tqnD2diALW2JGUSXFtyBkEoAyFTP-o">
                2019-03-19 "Mamos žurnalas" - "Globalus pasaulis ir užimti tėvai
                - kaip šiandieną ugdyti mažuosius Lietuvos patriotus?"{' '}
              </a>
            </em>{' '}
          </p>
          <p>
            <em>
              <a href="https://www.facebook.com/mazyliozurnalas/photos/rpp.219994168025188/3016817141676196/?type=3&theater">
                2019-05-01 žurnalas "Mažylis" - "Adaptacija darželyje - iššūkis
                ir mažiems, ir dideliems"{' '}
              </a>
            </em>{' '}
          </p>
        </span>
      )
    }
  ]

  return (
    <MyPage pageType="accordion" pageTitle={pageTitle} pageCards={pageCards} />
  )
}

export default PraktineInformacijaPage
